<template>
  <v-list nav dense class="px-5" v-if="IsLoggedIn">
    <v-list-item-group>
      <v-list-item v-if="IsLoggedIn" class="mr-2">
        <v-select v-model="selectedPartner" label="Gruppe" :items="Partners" item-title="name" item-text="name"
          item-value="key" v-on:change="onPartnerChange" :menu-props="{ bottom: true, offsetY: true }" />
      </v-list-item>
      <v-list-item v-if="IsLoggedIn">
        <v-select v-model="selectedGroups" label="Objekt" chips multiple :items="group" item-title="kurzName"
          item-text="kurzName" item-value="echtId" v-on:change="onGroupChange" :menu-props="{ bottom: true, offsetY: true }">
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.kurzName }}</span>
            </v-chip>
            <span v-if="index === 1" class="text-caption">
              (+{{ selectedGroups.length - 1 }})
            </span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item @click="toggleSelectAllGroups">
              <v-checkbox label="Select All" :color="someGroupsChecked ? 'indigo-darken-4' : undefined"
                :value="allGroupsChecked">
              </v-checkbox>
              <!-- <v-checkbox label="Select All" :color="someGroupsChecked ? 'indigo-darken-4' : undefined"
                :indeterminate="someGroupsChecked && !allGroupsChecked" :value="allGroupsChecked">
              </v-checkbox> -->
            </v-list-item>

            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-list-item>
    </v-list-item-group>

    <template v-for="(menuHeader, index) in DashboardConfig">
      <v-list-item-group v-if="IsLoggedIn" :key="index">

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ menuHeader.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-content>
          <template v-for="(item) in menuHeader.values">
            <v-list-item :disabled="isMenuItemDisabled(item)" v-if="item.workspaceId" link :key="item.configId"
              :to="'/report/' + item.reportName + '/' + item.reportPageName">
              <v-list-item-icon class="me-3">
                <v-icon>{{ item.menuIcon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.menuLabel }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-content>

        <v-list-group v-if="menuHeader.name !== 'Berichte'" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ menuHeader.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-badge v-if="menuHeader.name === 'Mapping'" color="red"
              :value="/*unmappedSalesItemsCount +*/ UnmappedCount > 0" :content="'!'" offset-x="-30" offset-y="10"
              overlap>
            </v-badge>
          </template>
          <v-list-item-content>

            <template v-for="(item) in menuHeader.values">
              <v-list-item :disabled="isMenuItemDisabled(item)" v-if="item.route" link :to="item.route.path"
                :key="item.configId">
                <v-list-item-icon class="me-3">
                  <v-icon>{{ item.menuIcon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.menuLabel }}</v-list-item-title>
                <v-badge v-if="!isMenuItemDisabled(item) && item.menuLabel === 'Konto'" color="red"
                  :value="UnmappedCount > 0" :content="UnmappedCount" overlap />
                <v-badge v-if="!isMenuItemDisabled(item) && item.menuLabel === 'Artikel' && unmappedSalesItemsCount > 0"
                  color="red" :value="unmappedSalesItemsCount > 0" :content="unmappedSalesItemsCount" overlap />
              </v-list-item>
            </template>
          </v-list-item-content>
        </v-list-group>
      </v-list-item-group>
    </template>
  </v-list>
</template>
<style lang="scss">
nav .row>.v-list {
  max-width: 100%;

  .v-badge__badge {
    margin-left: -30px;
  }
}
</style>
<script>
import axios from "axios";

const emitter = require('tiny-emitter/instance');

export default {
  name: "sideMenu",
  created() {
    emitter.on('updateUnmappedItemsCount', this.reduceUnmappedItemsCount);
  },
  mounted() {

  },
  methods: {
    toggleSelectAllGroups() {
      if (this.allGroupsChecked == true) {
        this.selectedGroups = [];
      } else {
        this.selectedGroups = this.group.map(obj => obj.echtId);
      }
      this.onGroupChange();
    },

    onPartnerChange() {
      this.selectedGroups = [this.PartnerDictionary[this.selectedPartner].values[0].echtId];
      this.onGroupChange();
    },

    onGroupChange() {
      this.$emit('SelectedGroupsChange', this.selectedGroups);
      this.getUnmappedItemsCount();
    },
    async getUnmappedItemsCount() {
      let api = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 100000000
      });

      if (this.selectedGroups.length > 0 && this.selectedGroup) {
        const response = await api.get(`GetUnmappedItemsCount`, {
          params: {
            echtId: this.selectedGroup
          }
        });
        if (response.data.length === 0) {
          this.unmappedSalesItemsCount = 0;
        } else {
          this.unmappedSalesItemsCount = response.data[0].count;
        }

      }
    },
    reduceUnmappedItemsCount() {
      this.unmappedSalesItemsCount--;
    },
    isMenuItemDisabled(item) {
      return (item.objectSelectionCount == "1" && this.selectedGroups.length != 1) ||
        (item.objectSelectionCount == "1+" && this.selectedGroups.length == 0);
    }

  },
  emits: {},
  computed: {
    group() {
      if (this.PartnerDictionary === undefined || this.PartnerDictionary === null || this.selectedPartner === "") {
        return [];
      } else {
        return this.PartnerDictionary[this.selectedPartner].values;
      }
    },
    selectedGroup: function () {
      return this.selectedGroups[0];
    },
    allGroupsChecked() {
      return this.selectedGroups.length === this.group.length
    },
    someGroupsChecked() {
      return this.selectedGroups.length > 0
    },
  },
  watch: {
    Partners: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.initialLoadingComplete === 0 && oldVal === undefined && newVal !== undefined && newVal.length !== 0) {
          this.initialLoadingComplete = 1;
          this.selectedPartner = this.Partners[0].key;
          this.onPartnerChange();
        }
      }
    },
  },
  props: {
    DashboardConfig: Array,
    HasGastronoviaPartner: Boolean,
    IsLoggedIn: Boolean,
    PartnerDictionary: Array,
    Partners: Array,
    UnmappedCount: Number,
  },
  data: () => ({
    selectedPartner: '',
    selectedGroups: [0],
    selectAllGroups: false,
    initialLoadingComplete: 0,
    open: ['Berichte'],
    unmappedSalesItemsCount: 0,
    test: -200
  }),
};
</script>
